/// <reference path="../all.d.ts" />

import React from 'react';
import{ useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import GatsbyImage from 'gatsby-image';
import bg from '../images/about_bg.svg';
import { ABOUT_US_ROUTE, CUSTOM_ORDER_REQUEST_ROUTE } from '../constants';

export default () => {
    const data = useStaticQuery(graphql`
        query {
            aboutUs1: file(relativePath: { eq: "about_us_1.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            aboutUs2: file(relativePath: { eq: "about_us_2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }`)
    return (
        <Layout pageTitle="About Us" pathname={ABOUT_US_ROUTE}>
            <>
                <header className="about-us-header">
                    <div style={{backgroundImage: `url(${bg})`}} className="background-image">
                    </div>
                    <div className="layout-container">
                        <h1 className="margin-bottom-2">ABOUT US</h1>
                        <p>Learn more about everything that goes into our custom creations and what makes Dzign Studio tick</p>
                    </div>
                    <div className="svg-container">
                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 90" preserveAspectRatio="none" width="100%" height="100%">
                            <path d="M802.35 42.51L802.35 0L0 0L0 72.86C2.64 72.23 4.29 71.84 4.95 71.68C83.87 52.92 165.83 50.86 245.59 65.62C279.71 71.93 281.06 72.18 320.17 79.42C383.5 91.14 448.57 89.73 511.34 75.28C539.18 68.87 533.21 70.25 562.46 63.51C620.24 50.21 680.44 51.75 737.46 67.99C746.11 70.45 767.74 76.61 802.35 86.47L802.35 42.51Z"/>
                        </svg>
                    </div>
                </header>
                <div className="layout-container">
                    <div className="about-us-container">
                        <h5 className="margin-bottom-2 margin-top-2">Hi! We are Sam and Jared Dziak, two individuals with a passion for creativity - it makes us happy.</h5>
                        <div className="img-container">
                            <GatsbyImage fluid={data.aboutUs2.childImageSharp.fluid} />
                        </div>
                        <p className="font-size-18">We love to learn, collaborate and create together. We started Dzign Studio after realizing how much we enjoyed customize craftings for our own wedding in May of 2020. From building an arbor to making our own wedding cake, we soaked up all of the hands-on elements that go into creating a special wedding day...even in the midst of a pandemic. Our original wedding vision incorporated A LOT of copper, and we grew to love working with it. Now it is one of the main things featured in our shop! You will also find handcrafted wooden signs, and many different versions of painted and digital creations. As part of this duo, Sam creates all of the designs with the acrylics, paints and lettering, while Jared builds the wooden and copper accents.</p>
                        <p className="font-size-18">We live in the Pittsburgh area and work out of a little studio space at our home. We have two trusted assistants, Boone and Hank, who always help us send out orders with a little extra love. We cannot wait to share our work as we learn new processes and hone our techniques together. We love a good challenge and trying new things, so if you have an idea that you do not see, please do not hesitate to <Link to={CUSTOM_ORDER_REQUEST_ROUTE} className="default">let us know</Link>! We are all about creating custom pieces that speak to YOU. Thanks for stopping by and supporting us on our little journey!</p>
                        <div className="img-container">
                            <GatsbyImage fluid={data.aboutUs1.childImageSharp.fluid} />
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    )
}